import React from "react";
import styled from "styled-components";
import { PURPLE, BLUE } from "../consts";

const GradientWapperBox = styled.div`
  position: relative;
`;

const GradientBox = styled.div`
  height: 1px;
  width: 1px;
  position: absolute;

  ${(props) => {
    switch (props.position) {
      case "topLeft":
        return `
          top: 0;
          left: 0;
        `;
      case "topRight":
        return `
          top: 0;
          right: 0;
        `;
      case "bottomLeft":
        return `
          bottom: 0;
          left: 0;
        `;
      case "bottomRight":
        return `
          bottom: 0;
          right: 0;
        `;
      default:
        alert("Unknown gradient position.");
    }
  }}

  > div {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background: radial-gradient(
      circle closest-side,
      ${(props) => {
        switch (props.color) {
          case "purple":
            return `${PURPLE}`;
          case "blue":
            return `${BLUE}`;
          default:
            return "red";
        }
      }},
      rgba(0, 0, 0, 0) 100%
    );
    background-repeat: no-repeat;
    width: 1500px;
    height: 1500px;
   transform:  ${(props) => {
        switch (props.position) {
          case "topLeft":
          case "bottomLeft":
            return `translate(-60%, -60%);`;
          case "topRight":
          case "bottomRight":
            return `translate(-30%, -60%);`;
          default:
            return "";
        }
      }}
  }
`;

export function GradientWapper({ children }) {
  return <GradientWapperBox>{children}</GradientWapperBox>;
}

export function Gradient({ position, color }) {
  return (
    <GradientBox position={position} color={color}>
      <div></div>
    </GradientBox>
  );
}
