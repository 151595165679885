import * as React from "react";
import Layout from "../components/layout";
import Block from "../components/block";
import Footer from "../components/footer";
import styled from "styled-components";
import { BREAKPOINTS } from "../consts";
import { useMediaQuery } from "react-responsive";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { Gradient, GradientWapper } from "../components/gradients";
import { Helmet } from "react-helmet";

export const query = graphql`
  query BlogQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          formattedDate: date(formatString: "LL")
          rawDate: date
          coverImage {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
              )
            }
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      id
      frontmatter {
        title
        summary
        formattedDate: date(formatString: "LL")
        rawDate: date
        coverImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`;

const Page = styled.div``;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 5;
  padding: 7rem 0 2rem;
  max-width: 40rem;

  h1 {
    padding-top: 0.5em;
    text-align: center;
  }
  
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    text-align: center;
    padding: 2rem 1rem 0rem;
    h1 {
      margin: 1rem 0 0;
    }
  }
`;

const ContentSection = styled.div`
  > article {
    max-width: 40rem;
    margin: 1em auto;
    text-align: left;

    .gatsby-img-attributes {
      margin: 0 auto;
    }
    .gatsby-resp-image-wrapper {
      margin: 3rem auto;
    }
    
    a {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
    }

    strong {
      font-weight: 500;
    }

    li {
      padding-bottom: 5px;
    }
    
    p, li {
      line-height: 1.5em;
      font-weight: 200;
      font-size: 20px;
      letter-spacing: .5px;
      color: rgba(248, 248, 248, 0.85);
      opacity: 1;
    }

    .gatsby-resp-image-wrapper {
      max-width: 300px !important;
      height: auto !important;
      width: auto !important;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    padding: 0 1rem;
    > article {
      p {
        font-size: 16px;
      }
    }
  }

`;

const ArticlesContainer = styled.div`
  h2 {
    text-align: center;
  }
`;
const ArticlesBoxes = styled.div`
  padding-top: 0.8rem;
  margin-bottom: 2rem;
  display: flex;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const ArticlesBox = styled.div`
  padding: 1rem;
  width: 33%;
  > a {
    > span {
      display: block;
      &.date {
        padding-top: .5rem;
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    width: 94%;
    margin: 0 0 2rem 0;
    padding: 0;
  }
`;

const RecentArticles = styled.div`
  max-width: 43.6363rem;
  margin: 4rem auto 6rem;

  span {
    font-size: 0.9em;
  }

  .date {
    padding-bottom: 0.2em;
    font-size: 0.8em;
  }

  a.invert {
    text-decoration: underline;
  }
`;

const PostPage = ({ data: { markdownRemark, allMarkdownRemark, site } }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });

  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  return (
    <Page>
      <Layout onlyPageTitle title={markdownRemark.frontmatter.title} socialImgSrc={getSrc(markdownRemark.frontmatter.coverImage)}>
        <Helmet>
        <script type="application/ld+json">{JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "Article",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://bifrostwallet.com/blog/"
          },
          "inLanguage": "en",
          "author": {
            "@type": "Organization",
            "name": "Towo Labs",
            "url": "https://towolabs.com"
          },
          "publisher":{
            "@type": "Organization",
            "name": "Towo Labs",
            "url": "https://towolabs.com"
          },
          "headline": markdownRemark.frontmatter.title,
          "image": site.siteMetadata.siteUrl + markdownRemark.frontmatter.coverImage.childImageSharp.gatsbyImageData.images.fallback.src,
          "datePublished": new Date(markdownRemark.frontmatter.rawDate).toISOString(),
          "dateModified": new Date(markdownRemark.frontmatter.rawDate).toISOString(),
        })}</script>
        </Helmet>
        <Block fullWidth={true}> 
          <HeaderSection>
            <span className="f-m-m">{markdownRemark.frontmatter.formattedDate}</span>
            <h1 className="f-4xl">{markdownRemark.frontmatter.title}</h1>
          </HeaderSection>
        </Block>

        <GradientWapper>
          <Gradient position="topLeft" color="blue" />
          <Block noPadding={true} fullWidth={true}>
            <ContentSection>
              <article dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
            </ContentSection>
          </Block>
        </GradientWapper>

        <GradientWapper>
          <Gradient position="topRight" color="purple" />
            <Block noPadding={true}>
              <ContentSection>
              <RecentArticles>
              <ArticlesContainer>
                <h2 className="f-3xl">Recent Articles</h2>
                <ArticlesBoxes>
                  {allMarkdownRemark?.nodes.map((article, key) => {

                    if (markdownRemark.id === article.id) {
                      return null;
                    }

                    return (
                      <ArticlesBox key={key}>
                        <Link className="article-card" to={article.fields.slug}>
                          <GatsbyImage
                            image={{...getImage(article.frontmatter.coverImage), width: 870, height: 600}}
                            alt={article.frontmatter.title}
                            quality={100}
                            placeholder="none"
                            objectFit="cover"
                            imgStyle={{borderRadius: 25}}
                          />
                          <span className="date f-m-m">
                            {article.frontmatter.formattedDate}
                          </span>
                          
                          <span className="title hover-effect light f-l-b">
                            {article.frontmatter.title}
                          </span>
                        </Link>
                      </ArticlesBox>
                    );
                  })}
                </ArticlesBoxes>
                <div style={{ textAlign: "center" }}>
                  <Link to="/blog/" className="invert">
                    View all articles
                  </Link>
                </div>
              </ArticlesContainer>
            </RecentArticles>
          </ContentSection>
        </Block>
        </GradientWapper>
        <Footer />
      </Layout>
    </Page>
  );
};

export default PostPage;
